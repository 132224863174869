



















import { Component, Vue } from 'vue-property-decorator';

import MainLogo from '@/layout/components/logo.vue';
import YwhzLangSelectCard from '@/components/PortalSite/LangSelectCard/index.vue';
import { otherModule } from '@/store/modules/other';

@Component({
    name: 'LoginMenu',
    components: {
        MainLogo,
        YwhzLangSelectCard
    }
})
export default class LoginMenu extends Vue {
    public tabData: any = [
        { label: this.$t('login.menu.lable[0]'), icon: 'iconfont icon-a-204shouye' },
        { label: this.$t('login.menu.lable[1]'), icon: 'iconfont icon-a-204shouye' }
    ];

    public jump(index) {
        switch (index) {
            case 0:
                this.$router.push({
                    name: 'Home'
                });
                break;
            case 1:
                window.open(this.shopConfig.ShopURL);
                break;
        }
    }

    private get shopConfig() {
        return otherModule.shopConfig;
    }
}
